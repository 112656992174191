@import "variables";

/* Home Page Styling */
#home-intro {
    min-height: 100vh;
    overflow: hidden;
    position: relative;

    img {
        height: auto;
        max-height: 80vh;
        opacity: .25;
        position: absolute;
        right: 0;
        user-select: none;
        width: 60%;
        z-index: 0;

        @media (max-width: $lt-xl-desktop) {
            right: -5%;
            width: 80%;
        }

        @media (max-width: $lt-desktop) {
            right: -50%;
            width: 150%;
        }

        
    }

    section {
        margin-bottom: auto;

        div {
            margin-left: 0;
            z-index: 1;

            h1 {
                font-size: 8rem;
                font-weight: bold;
                letter-spacing: .1rem;
        
                @media (max-width: $lt-desktop) {
                    font-size: 5rem;
                }
                
                @media (max-width: $lt-mobile) {
                    font-size: 3rem;
                }

                span {
                    display: block;
                }
            }
        
            p {
                margin: 3rem auto;
            }
        }
    }
}

#about-glrl {
    background-image: url('../images/downtown-lansing.jpg');
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    position: relative;

    span.overlay {
        background: rgba(0,0,0,.9);
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 0;
    }

    section {
        z-index: 1;
    }

    p:last-of-type {
        margin-bottom: 3rem;
    }
}

#philosophy {
    img {
        margin: 3rem 0;
    }
}

#contact-us {
    background-color: $primary-color;
    margin-top: 2rem;

    button[type="submit"] {
        background-color: #000000;
        color: #FFFFFF;

        &:hover {
            background-color: #333333;
        }
    }
}